// Small devices (landscape phones, 576px and up)
@media (max-width: 767.98px) {
  .header.container-fluid.activate-header {
    display: block !important;
  }

  .App-container {
    .header {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #FFFFFF;
      display: none;
    }
  }
  .header {
    margin-bottom: 10px;

    &-top-telephone {
      margin: 0 auto;
      display: revert !important;
      height: 100% !important;

      .header-main-logo {
        width: 75% !important;
        margin: 0 auto;
        padding: 10px;
        align-items: center;

        .dropdown {
          button {
            color: #FFFFFF;
          }

          &-menu {
            z-index: 999;
          }
        }
      }

      .dropdown-toggle, button {
        border: 1px solid #CCC;
        color: #FFFFFF;

        &:active {
          border: 1px solid #CCC;
        }

        &::after {
          display: none !important;
        }
      }
    }

    &-top-social {
      .info-content {
        margin: 0 !important;
      }
    }

    &-main {
      &-menu {
        .nav {
          &-item {
            width: 100% !important;
            align-items: start !important;
            text-align: start !important;
            justify-content: start !important;
            padding: 5px !important;

            a {
              padding: 5px !important;
            }
          }
        }
      }

      &-action {
        text-align: start;
        align-items: start;
        margin-bottom: 2px !important;
      }
    }
  }

  .home {
    margin-top: 0 !important;

    &-banner {
      &-content {
        margin-top: 20px !important;

        .carousel {

          &-item {
            min-height: 265px;
          }

          .action-link {
            text-align: center;

            button {
              width: 90% !important;
            }
          }

          &-caption {
            left: revert !important;
            right: revert !important;
            bottom: revert !important;
            top: 1% !important;
          }
        }

        &-text {
          .card {

            margin-top: 3rem !important;
            background-color: rgb(248 248 248 / 71%) !important;

            &-title {
              font-size: 14px !important;
              line-height: 21px !important;
              padding: 0 39px;
            }

            &-text {
              font-size: 11px !important;
              line-height: 17px !important;
              padding: 0 39px;
            }

            &-body {

              button {
                width: 110px;
                height: 33px;
                background: #482d7f;
                border-radius: 40.5px;
                font-family: var(--app-font);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
                margin-top: 0 !important;
                border: none !important;
              }
            }
          }
        }
      }
    }

    &-search {

      &-lookingfor {
        font-size: 15px;

        &-buttons {
          padding-top: 8px;

          button {
            font-size: 10px;
            padding: 5px !important;
            margin: 5px;
            border: 0.2px solid #F7F8FB;
            box-shadow: 0 1px 3px 0 lightblue;
          }
        }
      }

      &-text {

      }

      &-submit {
        button {
          margin: 0 auto;
          height: revert !important;
          width: 30%;

          svg {
            height: 16px !important;
          }
        }
      }

      .card {

        &-body {
          .input-group {
            height: revert !important;

            .form-control {
              font-size: 8px;
            }
          }
        }

        margin-top: 0 !important;
      }
    }
  }

  .need-help {

    &-container {
      padding-top: 60px;
    }

    &-content {
      height: revert !important;

      .need-help-info {
        div {
          margin-left: 10px;
          line-height: revert !important;
        }

        h4 {
          font-size: 14px;
          line-height: revert !important;
        }

        p {
          font-size: 10px;
          line-height: revert !important;
        }
      }

      .need-help-contact {
        padding: 10px;

        &-telephone {
          &-content {
            padding-top: 1px !important;
            width: 100%;

            .row {
              span {
                font-size: 14px;
                margin-left: 2px !important;
              }
            }
          }

          &-icon {
            svg {
              font-size: 12px;
              padding: 7px;
            }
          }

          &-text {
            font-size: 10px;
            line-height: 15px;
          }
        }
      }


    }

    &-image {
      height: 60px !important;

      img {
        margin-top: -18px !important;
        width: 50%;
        margin-left: revert !important;
      }
    }
  }

  // Testimonial css for mobile.
  .testimonial-container {
    .card {
      &-title {
        font-size: 24px !important;
        line-height: 115.5% !important;
      }

      &-text {
        font-size: 15px !important;
        line-height: 24px;
      }
    }

    &-cards {
      padding-top: 20px;

      .carousel {

        .card {

          .image-thumbnail {
            img {
              height: 70px;
              width: 70px;
            }
          }

          .image-quote {
            img {
              width: 28px;
            }
          }

          &-body {
            padding: 20px !important;
          }

          &-text {
            font-size: 13px !important;
          }

          &-title {
            font-size: 13px !important;
            float: right;
          }
        }
      }
    }
  }

  .health-type-container-fluid {
    .health-type {

      &-content {
        padding-top: 40px;
        text-align: center;

        h1 {
          font-size: 22px !important;
          line-height: 25px !important;
        }

        span {
          font-size: 14px;
          line-height: 14px;
        }

        .column {
          margin-top: 20px;

          .health-type-content-image {
            flex-direction: row !important;
            align-items: start !important;
            justify-content: start !important;

            .img-bg-div {
              height: 40px;
              width: 40px;

              img {
                width: 20px !important;
                height: 20px !important;
              }
            }

            .figure-caption {
              margin-left: 10px;
              line-height: 10px;
              font-size: 13px;
              margin-top: 17px;
            }
          }
        }
      }
    }
  }

  .popular-tests-container-fluid {
    padding-bottom: 20px !important;

    .related-tests-container {
      padding-top: 10px !important;

      .column {
        margin-bottom: 25px;

        &:nth-child(3) {
          .popular-tests-container-card {

            &-image {
              img {
                right: -3% !important;
              }
            }

            .card-img-overlay {
              img {
                width: 100% !important;
                right: -3% !important;
                margin-left: 0 !important;
              }
            }
          }
        }
      }

      &-title {
        text-align: center;
        margin-bottom: 20px;

        h3 {
          font-size: 25px;
          line-height: 37px;
        }

        button {
          padding: 9px !important;
          font-size: 9px !important;
          box-shadow: 0 1px 3px 0 #005BA4;
          border: none !important;
          margin-right: 10px !important;
          margin-bottom: 3px;
        }
      }
    }

    .popular-tests-container-card {
      .card-title {
        font-size: calc(var(--bs-gutter-x) * .8);
      }

      .card-text {
        font-size: 14px !important;
      }

      button {
        margin-top: 10px !important;
        font-size: 10px !important;
      }
    }
  }

  .frequently-booked-container-fluid {
    .frequently-booked-container {
      padding: 20px 0 30px 0;

      &-header {

        &-title {
          h3 {
            font-size: 25px !important;
            line-height: 40px !important;
          }
        }

        &-list {
          .row {
            div {
              span {
                font-size: 15px !important;
                line-height: 20px !important;
              }
            }

            svg {
              margin-right: 10px !important;
              height: 33px !important;
              width: 22px !important;
            }
          }
        }
      }
    }
  }

  .related-tests-container-fluid {
    .related-tests-container {
      padding-top: 10px;

      &-title {
        text-align: center;
        margin-bottom: 15px;
      }

      &-body {
        .column {
          margin-bottom: 10px;

          .price-value {
            margin-right: 13px;
          }

          .price-text {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .link-frequently-booked {
    .column {
      margin-bottom: 25px !important;

      .price-value {
        margin-right: 13px;
      }

      .price-text {
        font-size: 14px !important;
      }
    }
  }

  .home-stats-container-fluid {
    .home-stats-container {
      .row {
        .col-7 {
          &:nth-child(1) {
            display: block;
            padding-top: 20px;
            padding-bottom: 10px;
          }

          h2 {
            font-size: 18px !important;
            line-height: 25px !important;
            margin-left: 10px !important;
          }

          .status {
            padding-top: 5px;
            padding-bottom: 25px;

            .list-group {
              &-item {
                line-height: 10px !important;
                padding-right: 15px !important;
                padding-left: 15px !important;
                font-size: 12px;

                h5 {
                  font-size: 20px !important;
                  line-height: 130.5% !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .why-book-us-container-fluid {
    .health-type-container {
      .health-type-content {
        padding-top: 30px !important;

        .column {
          margin-top: 10px;

          .figure {
            p {
              font-size: 10px !important;
              padding-top: 1px !important;
              margin-bottom: 0 !important;
            }

            &-caption {
              font-size: 17px !important;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }

  .how-we-work-container-fluid {
    .health-type-container {
      .health-type-content {
        padding-top: 40px;

        .column {
          margin-top: 10px !important;

          .figure {
            &-caption {
              font-size: 16px;
              line-height: 10px;
              margin-top: 10px;
            }

            p {
              font-size: 10px;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  // Footer css.
  .footer {
    margin-top: 1px;
    padding-top: 10px;
    box-shadow: 0 -1px 3px 0 lightblue;

    &-content {
      padding-bottom: 10px !important;

      &-logo {
        text-align: center !important;

        img {
          width: 75% !important;
        }

        p {
          font-size: 10px !important;
          text-align: center;
        }
      }

      &-menu {
        padding-bottom: 20px;

        .nav-item {
          a {
            font-size: 10px !important;
            line-height: 10px !important;
          }
        }
      }

      &-social {
        .list-group {
          justify-content: center;
          margin-bottom: 10px;

          svg {
            margin-right: 10px;
            font-size: 17px;
          }
        }
      }

    }

    .info-content {
      &-title {
        font-size: 12px;
        line-height: 15px;
      }

      &-icon {
        svg {
          background-color: #00ab9b;
          padding: 8px;
          border-radius: 30px;
          color: #FFFFFF;
          height: 0.6em
        }
      }

      &-body {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .page {
    padding: 20px;

    &-title {
      text-align: center;
      padding-top: 20px !important;
      padding-bottom: 10px !important;
    }

    .our-services-content {
      &-block {
        padding-bottom: 30px;
        align-items: center;
        justify-content: center;

        &-list {
          & > div {
            padding-bottom: 10px;
            padding-top: 10px;

            .services {
              box-shadow: 0 1px 3px 0 lightblue;
            }
          }
        }

        &-header-description {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }

    &_404 {
      padding: 20px 0;

      .four_zero_four_bg {
        background-size: 100% 100%;
      }

      .contant_box_404 {
        padding-top: 20px;
      }
    }

    .about-us-content {
      justify-content: center;
      align-items: center;

      .directors {
        justify-content: center;

        h2 {
          font-size: 28px;
          line-height: 33px;
        }

        &-view {
          align-items: center;
          justify-content: center;

          .our-directors {
            &-title {
              text-align: center;
            }

            &-list {
              &-image {
                img {
                  width: 150px;
                  height: 150px
                }
              }

              &-content {
                font-size: 17px;
                line-height: 25px;
                text-align: center;
              }
            }
          }
        }

        &-vision {
          .services {
            box-shadow: 0 1px 3px 0 lightblue;
            margin: 10px;
          }
        }
      }

      .vision {
        &-list {
          margin-top: 30px;

          .services {
            box-shadow: 0 1px 3px 0 lightblue;
            margin: 10px;
          }
        }

        h2 {
          font-size: 28px;
          line-height: 35px;
        }

        &-description{
          font-size: 15px !important;
        }
      }

      p {
        font-size: 17px;
        line-height: 30px;
        padding-bottom: 10px;
      }
    }

    .contact-us {
      .row {
        & .col-md-6 {
          margin-bottom: 30px;
        }
      }

    }
  }

  .location-model .location-list {
    margin: 0 auto !important;
  }
}