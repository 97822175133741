.home {
	padding: 0;

	&-banner-content {

		margin-top: 30px;

		&-text {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			margin-top: 6%;

			.card {
				text-align: left;
				background-color: transparent !important;
				border: none;

				&-body {
					padding: 0 !important;

					button {
						width: 208px;
						height: 47px;
						background: #482d7f;
						border-radius: 37.5px;
						font-family: var(--app-font);
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 30px;
						color: #FFFFFF;
						margin-top: 15px;
						border: none !important;

						&:hover {
							background: #482d7f;
						}
					}
				}

				&-title {
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 400;
					font-size: 32px;
					line-height: 66px;
					color: #005BA4;
				}

				&-text {
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 400;
					font-size: 17px;
					line-height: 31px;
					color: #000000;
				}
			}
		}

		&-image {

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: 35rem;
			}
		}
	}

	&-search {

		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		&-form {
			padding: 13px;
		}

		&-text {
			padding-right: 13px;
			padding-left: 13px;
		}

		.card {
			min-height: 250px;
			background: #FFFFFF;
			box-shadow: 0 4px 50px rgb(0 0 0 / 12%);
			border-radius: 30px;
			border-width: 0;
			margin-top: -11%;

			&-body {

				.input-group {
					background: #F7F8FB;
					border: 1px solid #005BA4;
					border-radius: 14px !important;
					height: 50px;

					&-text {
						background-color: #F7F8FB !important;
						border-radius: 14px !important;
						border: none !important;

						svg {
							height: 22px !important;
							color: #005BA4 !important;
						}
					}

					.form-control {
						background-color: #F7F8FB !important;
						border-radius: 14px !important;
						border: none !important;
						font-family: var(--app-font);
						font-style: normal;
						font-weight: 400;
						font-size: 12px;
						line-height: 24px;
						color: #82787A;
						padding: 0;

						&:focus {
							border: none !important;
							box-shadow: none !important;
						}
					}
				}
			}
		}

		&-submit {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			button {
				width: 75px;
				height: 50px;
				background: #482d7f;
				border-radius: 14px;
				color: #FFFFFF;

				&:hover {
					background: #482d7f !important;
					color: #FFFFFF !important;
				}

				svg {
					height: 22px !important;
				}
			}
		}

		&-lookingfor {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;


			font-family: var(--app-font);
			font-style: normal;
			font-weight: 400;
			font-size: 26px;
			line-height: 31px;

			color: #000000;

			&-buttons {
				text-align: left;
				padding-top: 28px;

				button {
					margin-right: 20px;
					background: #F7F8FB;
					border: 0.8px solid #005BA4;
					border-radius: 14px;
					padding: 12px;
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 400;
					font-size: 15px;
					color: #000000;
				}
			}
		}
	}

	.need-help-container-fluid, .health-type-container-fluid, .related-tests-container-fluid, .testimonial-container-fluid, .popular-tests-container-fluid {
		background: #FFFFFF !important;
	}

	.frequently-booked-container-fluid{
		background: #7C42AD;
		padding-bottom: 150px;
	}

	.why-book-us-container-fluid, .how-we-work-container-fluid {
		background-color: #FFFFFF;

		.health-type-content {
			text-align: center !important;
		}
	}

	.popular-tests-container-fluid{
		padding-bottom: 80px;
	}

	.link-frequently-booked{

		margin-top: -200px;

		.related-tests-container{
			padding: 0 !important;

			.related-tests-container-title{
				margin-bottom: 0 !important;
			}
		}

	}
}
