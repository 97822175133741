.health-type-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	padding-top: 80px;
	padding-bottom: 20px;

	h1 {
		font-family: var(--app-font);
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		line-height: 41px;
		/* identical to box height */


		color: #000000;
	}

	span {
		font-family: var(--app-second-font);
		font-style: normal;
		font-weight: 400;
		font-size: 21px;
		line-height: 28px;

		color: #000000;
	}

	.column {
		text-align: center;
		margin-top: 60px;

		.health-type-content-image {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.img-bg-div {
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				height: 80px;
				width: 80px;

				img {
					background-color: transparent;
				}
			}

			&.health-type-content-image-liver {
				.img-bg-div {
					background: #F7CFFD
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-heart {
				.img-bg-div {
					background: #B3F8FE;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-kidney {
				.img-bg-div {
					background: #FFED9F;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-thyroid {
				.img-bg-div {
					background: #FFC5B0;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-diabetes {
				.img-bg-div {
					background: #CEBFFF;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-1 {
				.img-bg-div {
					background: #B3F8FE;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-2 {
				.img-bg-div {
					background: #E0E0F7;
				}

				img {
					background-color: transparent;
					width: 30px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-3 {
				.img-bg-div {
					background: #F7CFFD;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-4 {
				.img-bg-div {
					background: #FFED9F;
				}

				img {
					background-color: transparent;
					width: 30px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-5 {
				.img-bg-div {
					background: #FFC5B0;
				}

				img {
					background-color: transparent;
					width: 40px !important;
					height: 40px !important;
				}
			}

			&.health-type-content-image-6 {
				.img-bg-div {
					background: #CEBFFF;
				}

				img {
					background-color: transparent;
					width: 30px !important;
					height: 40px !important;
				}
			}


			img {
				border: none !important;
				width: 40px;
				height: 40px;
			}

			figcaption {
				margin-top: 20px;
				color: #000000;
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 32px;
				text-align: center;
			}

			p {
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				text-align: center;
				padding-top: 10px;

				color: #82787A;
			}
		}
	}
}
