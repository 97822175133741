/*======================
    404 page
=======================*/


.page_404 {
	padding: 40px 0;
	background: #fff;
	font-family: var(--app-font);

	img {
		width: 100%;
	}

	.text-center{
		margin: 0 auto;
	}

	.four_zero_four_bg {
		background-image: url('../../img/404-medical.jpeg');
		height: 400px;
		background-position: center;
		background-repeat: no-repeat;

		h1 {
			font-size: 80px;
		}

		h3 {
			font-size: 80px;
		}
	}

	.link_404 {
		color: #fff !important;
		padding: 10px 20px;
		background: #7C42AD;
		margin: 20px 0;
		display: inline-block;
	}

	.contant_box_404 {
		margin-top: -50px;
	}
}
