.our-services-content {

	&-block{
		padding-bottom: 50px;

		&-header{
			&-title{
				font-family: var(--app-font);
				font-style: normal;
				font-weight: 400;
				font-size: 40px;
				line-height: 55px;
				color: #000000;
			}

			&-description{
				font-family: var(--app-second-font);
				font-style: normal;
				font-weight: 400;
				font-size: 23px;
				line-height: 30px;

				color: #000000;
			}
		}

		&-subheading{
			padding-bottom: 30px;
			padding-top: 30px;
		}

		&-list{
			>div{
				padding-bottom: 30px;
				padding-top: 30px;
			}
		}
	}

}
