.contact-us{
	padding: 10px;
	font-family: var(--app-font);

	.form-control{
		background-color: #F7F8FB !important;
		border-radius: 14px !important;
		font-family: var(--app-font);
		font-style: normal;
		font-weight: 400;
		color: #82787A;

		border: 1px solid #482d7f;
		margin-bottom: 13px;
	}
}
