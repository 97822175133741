.testimonial-container{
	.card{
		text-align: left;
		border: none !important;

		&-title{
			font-family: var(--app-second-font);
			font-style: normal;
			font-weight: bold;
			font-size: 42px;
			line-height: 144.5%;
			color: #005BA4;
		}

		&-text{

			font-family: var(--app-second-font);
			font-style: normal;
			font-weight: 400;
			font-size: 30px;
			line-height: 38px;

			color: #000000;
		}
	}

	&-cards{
		padding-top: 60px;

		.card{
			&-body{
				padding-bottom: 20px !important;
			}
		}

		.col-4, .col-sm-12, .col-md-4 {
			&:nth-child(1) {
				.card {
					background: #E0E0F7;
				}
			}

			&:nth-child(2) {
				.card {
					background: #B3F8FE;
				}
			}

			&:nth-child(3) {
				.card {
					background: #FFC5B0;
				}
			}

			.card{
				border-radius: 35px;
				background: transparent;

				&-body{
					padding: 40px;
				}

				&-text{
					font-family: var(--app-font);
					font-style: normal;
					font-weight: 400;
					font-size: 0.938rem;
					line-height: 144.5%;
					color: #000000;
				}

				&-title{

					font-family: var(--app-second-font);
					font-style: normal;
					font-weight: bolder;
					font-size: 22px !important;
					line-height: 144.5%;
					color: #005BA4;
				}

				.image{
					margin-bottom: 20px;

					&-thumbnail{
						img {
							height: 88px;
							width: 90px;
							background: #F4F9FD;
							border-radius: 50% !important;
							padding: 7px 7px 0 7px;
						}
					}

					&-quote {
						margin-top: 40px;
						text-align: right;

						img{
							width: 50px;
						}
					}
				}
			}
		}
	}
}
