@import url('https://fonts.googleapis.com/css2?family=Raleway&display=Raleway');

@font-face {
  font-family: "KeepCalm-Medium";
  src: local("KeepCalm-Medium"),
  url("./fonts/KeepCalm-Medium.ttf") format("truetype");
  font-weight: bold;
}

:root{
  --app-font: "KeepCalm-Medium";
  --app-header-color: #7C42AD;
  --app-second-font: 'Raleway';
}

body {
  margin: 0 auto !important;
  font-family: var(--app-font), serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{
  max-width: 1140px !important;
}

ul{
  list-style: none;
}

.carousel-indicators{
  display: none !important;
}
