.services{
	box-shadow: 6px 4px 40px 20px rgba(241, 242, 246, 0.25);
	border-radius: 22px;
	border: none;

	min-height: 210px;

	.card-body{
		padding-bottom: 10px;
		padding-left: 30px;
		padding-right: 30px;

		.figure{
			display: block !important;
			margin-bottom: 0 !important;
		}
	}

	.card-title{
		font-family: var(--app-font);
		font-style: normal;
		font-weight: 400;
		font-size: 19px;
		line-height: 30px;


		color: #000000;
	}

	.card-text{
		font-family: var(--app-second-font) !important;
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 12px !important;
		line-height: 19px !important;
		color: #000000 !important;
		padding-bottom: 0 !important;
	}
	&-overlay {

		div:nth-child( 2 ){
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&-image {
			img {

				&:nth-child(2) {
					position: absolute;
					left: 33px;
					z-index: 1;
					top: 40px;
				}
			}

		}
	}
}
